import React, { useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

import Content from '../';

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Form from '../components/SignUp/Form';
import Success from '../components/SignUp/Success';

import styled from 'styled-components';

const Image2 = styled(Img)`
    padding: 2.5px;
    border-radius: 25px;
    max-width: 500px;
    height: auto;

    @media (max-width: 1200px) {
        max-width: 100%
    }
`

const StyledIcon2 = styled(FontAwesomeIcon)`
    font-size: 18px;
    margin-left: 8px;
`;

const AboutGabo = ({data}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }

    return (
        <Layout>
            <SEO title="About Gabo"/>
            <Content.Container>
                <br/>
                <div align='center'>
                    <div style={{ maxWidth: `350px`}}>
                        <Image2
                            title="The Gift of Gabo"      
                            alt="Amongst clouds, we see the letters 'The Gift of Gabo,' where the trailing 'o' is a hot air balloon."
                            fluid={data.file.childImageSharp.fluid}
                        />
                    </div>
                </div>
                <br/>
                <Content.Title>About Gabo</Content.Title>
                <Content.Text><b>Upshot:</b> Gabo is a foreign language mobile app that introduces you 
                to a new work of foreign literature or media daily.
                
                <br/><br/>A daily quote (from said work) is accompanied by an explanation of the work and its 
                historical context, as well as relevant information about the showcased language.
                
                <br/><br/>The first version of Gabo will be focused on content originally  
                in Spanish. Read more about it on the official Gabo App website: <a href="https://www.spanishlit.com" target="_blank" rel="noopener noreferrer">spanishlit.com</a><StyledIcon2 icon={faExternalLinkAlt}/>.
                </Content.Text>
                <br/>
                {/* <div align='center'>
                    <Content.Text>
                        ~
                    </Content.Text>
                </div>
                <Content.Text>
                    Learn more about the Gabo app by signing up for the e-mail list! :)
                </Content.Text>
                {!isSubmitted ? 
                    <Form submitForm={submitForm}/> :
                    <Success/>
                }
                <br/> */}
            </Content.Container>
        </Layout>
    )
}

export default AboutGabo

export const query = graphql`  
  query {    
    file(relativePath: {eq: "giftOfGabo.jpeg"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;